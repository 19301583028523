import $ from 'jquery';
/**
 * Config object
 *
 * global shared variables
 */
const config = {
  body: {
    $: $('body'),
  },
  ajax: window.wsf.ajax_url,
  nonce: window.wsf.nonce,
};

module.exports = config;
