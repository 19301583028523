/*
  Project: Wsf
  Author: Wisdmlabs
 */

import 'babel-polyfill';
// globals
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';

// Modules
import './modules';

// vendors
import './vendors';

// views
import './views';
