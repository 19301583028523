import $ from 'jquery';

const lazyImage = () => {
  const config = {
    // If the image gets within 200px in the Y axis, start the download.
    rootMargin: '200px 0px',
    threshold: 0.01,
  };
  let observer;
  const images = document.querySelectorAll('img[data-lazy-src]');
  let imageCount = images.length;

  /**
   * Apply the image
   * @param {object} image The image object.
   */
  const applyImage = image => {
    const theImage = $(image);

    if (!theImage.length) {
      return;
    }

    const src = theImage.attr('data-lazy-src');
    if (!src) {
      return;
    }

    const srcset = theImage.attr('data-lazy-srcset');
    const sizes = theImage.attr('data-lazy-sizes');
    const theClone = theImage.clone();

    // Remove lazy attributes from the clone.
    theClone.removeAttr('data-lazy-src');
    theClone.removeAttr('data-lazy-srcset');
    theClone.removeAttr('data-lazy-sizes');

    // Add the attributes we want on the finished image.
    theClone.addClass('lazy-image--handled');
    theClone.attr('src', src);

    if (srcset) {
      theClone.attr('srcset', srcset);
    }
    if (sizes) {
      theClone.attr('sizes', sizes);
    }

    theImage.replaceWith(theClone);
  };
  /**
   * On intersection
   * @param {array} entries List of elements being observed.
   */
  const onIntersection = entries => {
    // Disconnect if we've already loaded all of the images
    if (imageCount === 0) {
      observer.disconnect();
    }

    // Loop through the entries
    entries.forEach(entry => {
      // Are we in viewport?
      if (entry.intersectionRatio > 0) {
        imageCount -= 1;

        // Stop watching and load the image
        observer.unobserve(entry.target);
        applyImage(entry.target);
      }
    });
  };

  const lazyImageInit = async () => {
    // If initialized, then disconnect the observer
    if (observer) {
      observer.disconnect();
    }
    // If we don't have support for intersection observer, loads poll
    if (!('IntersectionObserver' in window)) {
      await import('intersection-observer');
    }
    // It is supported, load the images
    observer = new IntersectionObserver(onIntersection, config);

    images.forEach(img => {
      if (img.classList.contains('lazy-image--handled')) {
        return;
      }
      observer.observe(img);
    });
  };
  lazyImageInit();
};

lazyImage();
