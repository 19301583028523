import $ from 'jquery';

import config from '../config';
import { supportsWebp } from './utils';

/**
 *
 *
 * @class App
 */
class App {
  constructor() {
    this.iswebpSupported();
    this.listenMenuOpen();
  }
  /**
   *
   *
   * @param {string} cls
   * @memberof App
   */
  addBodyClass(cls) {
    config.body.$.addClass(cls);
  }
  /**
   *
   *
   * @returns wp nounce
   * @memberof App
   */
  getNounce() {
    return config.nonce;
  }

  async iswebpSupported() {
    const webp = await supportsWebp();
    if (!webp) {
      this.addBodyClass('nowebp');
    }
  }
  /**
   * Toggles menu on click
   *
   * @memberof App
   */
  listenMenuOpen() {
    $('.menu-toggle-open').on('click', () => {
      $('body').addClass('menu--is-open');
    });
    $('.menu-toggle-close').on('click', () => {
      $('body').removeClass('menu--is-open');
    });
    $('.menu-primary-container').on('click', e => {
      if ($(e.toElement).hasClass('menu-primary-container')) {
        $('body').removeClass('menu--is-open');
      }
    });
  }
}

export default new App();
