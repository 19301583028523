/**
 *
 *
 * Views
 *
 * All views should contain init method that will be called automatically.
 */

import { routeMatch } from '../modules/utils';

// Single Course Scripts
if (routeMatch('courses/$')) {
  console.info('Matched Course Archive Page');
  import('./CourseArchive.js')
    .then(module => {
      const CourseArchive = module.default;
      const instance = new CourseArchive();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}

// Single Course Scripts
if (routeMatch('courses/[^/]*/$')) {
  console.info('Matched Course Single Page');
  import('./CourseSingle.js')
    .then(module => {
      const CourseSingle = module.default;
      const instance = new CourseSingle();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}

// Watchlist Scripts
if (routeMatch('/watchlist')) {
  console.info('Matched watchlist Page');
  import('./Watchlist.js')
    .then(module => {
      const Watchlist = module.default;
      const instance = new Watchlist();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}

// Whiteboard Scripts
if (routeMatch('/whiteboards')) {
  console.info('Matched whiteboard Page');
  import('./Whiteboard')
    .then(module => {
      const Whiteboard = module.default;
      const instance = new Whiteboard();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}

// topic discussions scripts

if (routeMatch('/lessons/[^/]*/$')) {
  console.info('Matched TopicSingle Page');
  import('./TopicSingle.js')
    .then(module => {
      const TopicSingle = module.default;
      const instance = new TopicSingle();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}

// forum topic ellipse click
if (routeMatch('/forums.*$')) {
  import('./ForumTopic.js')
    .then(module => {
      const ForumTopic = module.default;
      const instance = new ForumTopic();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}

// forum topic ellipse click
if (routeMatch('/projects')) {
  import('./Projects.js')
    .then(module => {
      const Projects = module.default;
      const instance = new Projects();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}

if (
  !document.body.classList.contains('is-mobile') &&
  document.body.classList.contains('home')
) {
  console.info('Matched dashboard Page');
  import('./Dashboard.js')
    .then(module => {
      const Dashboard = module.default;
      const instance = new Dashboard();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}

if (routeMatch('/profile')) {
  console.info('Matched profile Page');
  import('./Profile.js')
    .then(module => {
      const Profile = module.default;
      const instance = new Profile();
      instance.init();
    })
    .catch(e => {
      console.log(e);
    });
}


import('../modules/top-header.js')
  .then(module => {
    const TopHeader = module.default;
    const instance = new TopHeader();
    instance.init();
  })
  .catch(e => {
    console.log(e);
  });

import('./calendar.js');
