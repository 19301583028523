export const supportsWebp = async () => {
  if (!window.createImageBitmap) {
    return false;
  }

  const webpData =
    'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  const res = await fetch(webpData);
  const blob = await res.blob();

  try {
    await createImageBitmap(blob);
    return true;
  } catch (e) {
    return false;
  }
};

export function routeMatch(ex) {
  const re = new RegExp(ex);
  return re.exec(window.location.pathname);
}
