import './App.js';
import './lazyImage.js';
// import { currentMenuColor } from './utils';

// App module initialization and codes

// Mathjax initialization and code
// if (document.body.classList.contains('is-math')) {
// import('./mathjax');

if (window.location.search.includes('tab=files')) {
  // delay katex in project file upload page because of vuejs error
} else {
  import('./katex');
}
